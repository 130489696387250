function Solutions(clue) {
  const solutions = {
    1: ["play it by the ear", "it by"],
    2: ["cucumber"],
    3: ["chip off the old block"],
    4: ["break a leg"],
    5: ["blue in the face"],
    6: ["light at end of the tunnel"],
    7: ["as"],
    8: ["no record"],
    9: ["health"],
    10: ["for"],
    11: ["gene"],
    12: ["eye", "eye of the hurricane"],
    13: ["56"],
    14: ["cc", "c*c", "c^2", "pythagorean theorem"],
    15: ["126"],
    16: ["/3", "third"],
    17: ["10-3e"],
    18: ["1", "one"],
    19: ["console.log"],
    20: ["python"],
    21: ["std::cout"],
    22: ["def"],
    23: ["rust"],
    24: ["go"],
    25: ["romeo"],
    26: ["the great gatsby"],
    27: ["hamlet"],
    28: ["jane eyre"],
    29: ["a tale of two cities"],
    30: ["wizard of oz"],
    31: ["nyc", "new york", "new york city"],
    32: ["vegas", "las vegas"],
    33: ["baltic sea", "the baltic sea"],
    34: ["grand canyon", "the grand canyon"],
    35: ["the nile", "nile"],
    36: ["java"],
    37: ["french toast"],
    38: ["spanish flu"],
    39: ["english muffins"],
    40: ["dutch oven"],
    41: ["hawaiian pizza"],
    42: ["japanese onsen"],
    43: ["7 colors", "rainbow", "seven colors"],
    44: ["7 dwarves", "seven dwarves"],
    45: ["7 seas"],
    46: ["7 planets"],
    47: ["days of the week"],
    48: ["7 minutes in heaven"],
  };

  return solutions[clue]; //return a boolean based on if user got the answer right or not
}

export default Solutions;
