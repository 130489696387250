function Clues(ID) {
  const lines = {
    1: "PLAYEAR",
    2: "COOLASA",
    3: "OLDBLOC",
    4: "AXLXEXG",
    5: "FABLUCE",
    6: "TNNLITE",
    7: "AOFASAP",
    8: "NROFPNR",
    9: "HOFOSHA",
    10: "FOFSPCA",
    11: "GOFKISS",
    12: "IOFIRMA",
    13: "7TIMES8",
    14: "AAADDBB",
    15: "9CHOOS5",
    16: "4?PIRRR",
    17: "TENMODE",
    18: "|ISQRD|",
    19: "PRINTJS",
    20: "SERPENT",
    21: "PRNTC++",
    22: "BOAF(X)",
    23: "TETANUS",
    24: "CHECKRS",
    25: "JULIET&",
    26: "THEGR8J",
    27: "2B|!2B",
    28: "READERI",
    29: "BESTOFX",
    30: "NOTINKS",
    31: "BIGAPPL",
    32: "SINCITY",
    33: "BALTICC",
    34: "BIGHOLE",
    35: "REFUSAL",
    36: "ESPRSSO",
    37: "LETOAST",
    38: "LAGRIPE",
    39: "MUFFINS",
    40: "FORNUIS",
    41: "PAIPIKA",
    42: "WENQUAN",
    43: "ROYGBIV",
    44: "SNOWYT&",
    45: "CCCCCCC",
    46: "NOEARTH",
    47: "ENDSINY",
    48: "FASTSIN",
  };
  return lines[ID]; //return the text
}
export default Clues;
