import React, { useId, useState } from "react";
import "./septogram.css";
import Gameplay from "./gameplay";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Clues from "./clues";
import Solutions from "./solutions";

function myFunction(ID) {
  let answer = prompt(Clues(ID));
  if (answer != null) {
    if (Solutions(ID).includes(answer.toLowerCase())) {
      alert("You got it right!");

      if (localStorage.getItem("set") === null) {
        let solved = [ID];
        localStorage.setItem("set", JSON.stringify(solved));
      } else {
        let solved = JSON.parse(localStorage.getItem("set"));
        const newArr = [...solved, ID];
        localStorage.setItem("set", JSON.stringify(newArr));
      }

      window.location.reload();
    } else {
      alert("Sorry, try again.");
    }
  }
}

function Grid() {
  const rows = 8;
  const cols = 6;
  const grid = [];
  let solved = [];
  if (localStorage.getItem("set") !== null) {
    solved = JSON.parse(localStorage.getItem("set"));
  }
  console.log(solved);

  for (let row = 0; row < rows; row++) {
    const currentRow = [];
    for (let col = 0; col < cols; col++) {
      const ID = col + row * 6 + 1;
      if (solved.includes(ID)) {
        currentRow.push(
          <span
            key={`${row}-${col}`}
            className="cell"
            onClick={() => myFunction(ID)}
            style={{ backgroundColor: "#fae6a0" }}
          >
            {ID}
          </span>
        );
      } else {
        currentRow.push(
          <span
            key={`${row}-${col}`}
            className="cell"
            onClick={() => myFunction(ID)}
          >
            {ID}
          </span>
        );
      }
    }
    grid.push(
      <div key={row} className="row">
        {currentRow}
      </div>
    );
  }

  return (
    <div className="body">
      <div className="header">
        <div className="h1">SEPTOGRAM</div>
        <p>
          Made in honor of Jack Lance, the creator of{" "}
          <a href="https://jacklance.github.io/octogram">OCTOGRAM</a>.
        </p>
        <p style={{ fontSize: 12 }}>
          Each row of puzzles share a similarity. The difficulty of each row
          increases from left to right.
        </p>
      </div>
      <div className="grid">{grid}</div>
      <p style={{ paddingBottom: 25, fontSize: 12 }}>
        With special thanks to beta testers{" "}
        <a href="https://twitter.com/ajay9470">Ajay</a>,{" "}
        <a href="https://twitter.com/asfarazicansee">Faraz</a>,{" "}
        <a href="https://twitter.com/sohi_patel">Sohi</a>,{" "}
        <a href="https://twitter.com/farzaan_s_">Farzaan</a>,{" "}
        <a href="https://twitter.com/AmosHebb">Amos</a>.
      </p>
    </div>
  );
}

export default Grid;
